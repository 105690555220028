import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { getFeedback } from "../../../services/moodle/courses";

export const ProgressRowItemDetail = ({
  detail,
  login_key,
  authLoginConsumed,
  setAuthLoginConsumed,
}) => {
  /*   console.log(detail); */
  const [feedback, setFeedback] = React.useState("-");
  const [generalFeedback, setGeneralFeedback] = React.useState("-");
  const { user } = useAuth();

  React.useEffect(() => {
    if (
      detail.itemmodule === "quiz" &&
      detail.graderaw &&
      detail.iteminstance
    ) {
      const args = {
        token: user.token,
        quizid: detail.iteminstance,
        grade: detail.graderaw,
      };
      getFeedback(args).then(
        (res) => res.feedbacktext !== "" && setGeneralFeedback(res.feedbacktext)
      );
    } else {
      if (detail.feedback !== "" && feedback === "-") {
        handleImgToken(detail.feedback, user.token).then((res) =>
          setFeedback(res)
        );
      }
    }
    //eslint-disable-next-line
  }, []);

  const handleImgToken = async (html, token) => {
    // Crear un elemento div para cargar el HTML como texto
    let src_token_with_param = `?token=${token}`;
    let src_token_without_param = `&token=${token}`;
    let div = document.createElement("div");
    div.innerHTML = html;

    // Obtener todas las etiquetas de imagen
    let imagenes = div.getElementsByTagName("img");
    let audios = div.getElementsByTagName("source");

    // Recorrer todas las etiquetas de imagen y agregar el token al final del atributo src
    for (let i = 0; i < imagenes.length; i++) {
      let src = imagenes[i].getAttribute("src");
      if (src.includes("?")) {
        if (!authLoginConsumed) {
          console.log("imagenes: ", authLoginConsumed);
          const url = `https://par.tuclase.cl/auth/userkey/login.php?key=${login_key}&wantsurl=${src}`;
          imagenes[i].setAttribute("src", url);
          setAuthLoginConsumed(true);
        } else {
          imagenes[i].setAttribute("src", src + src_token_without_param);
        }
      } else {
        if (!authLoginConsumed) {
          console.log("imagenes: ", authLoginConsumed);
          const url = `https://par.tuclase.cl/auth/userkey/login.php?key=${login_key}&wantsurl=${src}`;
          imagenes[i].setAttribute("src", url);
          setAuthLoginConsumed(true);
        } else {
          imagenes[i].setAttribute("src", src + src_token_with_param);
        }
      }
    }

    for (let i = 0; i < audios.length; i++) {
      let src = audios[i].getAttribute("src");
      if (src.includes("?")) {
        if (!authLoginConsumed && imagenes.length === 0) {
          console.log("audios: ", authLoginConsumed);
          const url = `https://par.tuclase.cl/auth/userkey/login.php?key=${login_key}&wantsurl=${src}`;
          audios[i].setAttribute("src", url + src_token_without_param);
          setAuthLoginConsumed(true);
        } else {
          audios[i].setAttribute("src", src + src_token_without_param);
        }
      } else {
        if (!authLoginConsumed && imagenes.length === 0) {
          console.log("audios: ", authLoginConsumed);
          const url = `https://par.tuclase.cl/auth/userkey/login.php?key=${login_key}&wantsurl=${src}`;
          audios[i].setAttribute("src", url + src_token_with_param);
          setAuthLoginConsumed(true);
        } else {
          audios[i].setAttribute("src", src + src_token_with_param);
        }
      }
    }

    // Devolver el HTML modificado
    return div.innerHTML;
  };

  return (
    <>
      <tr>
        <th scope="row" className="title-row">
          {detail.itemname}
        </th>
        <td>{detail.gradeformatted || "-"}</td>
        <td>{detail.percentageformatted}</td>
        <td
          dangerouslySetInnerHTML={{
            __html: feedback,
          }}
        ></td>
        <td
          dangerouslySetInnerHTML={{
            __html: generalFeedback,
          }}
        ></td>
      </tr>
    </>
  );
};
